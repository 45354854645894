export const apiUrl = import.meta.env.VITE_API_URL;
export const AUTH_TOKEN = 'auth_token';
export const SESSION = 'session';
export const BEARER = 'Bearer';
export const token = import.meta.env.VITE_PUBLIC_STRAPI_API_TOKEN;
export const url = import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'production' ? apiUrl : '/api';

export let origin = '';
export const getOrigin = (value: string) => (origin = value);

export const server_url =
    import.meta.env.VITE_ENV_MODE === 'staging'
        ? import.meta.env.VITE_STAGING_BACKEND_URL
        : import.meta.env.VITE_PRODUCTION_BACKEND_URL;
