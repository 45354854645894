<template>
    <div class="relative flex flex-col justify-between min-h-screen">
        <component :is="'style'" v-if="user.isLoggedIn">
            :root { --popover-bg: var(--info--dark-color); --popover-color: var(--dark--color-invert) }
        </component>
        <Header :data="!!data?.top_navigation ? data?.top_navigation : undefined" />
        <main v-bind="$attrs" class="flex-1 min-h-full">
            <router-view v-slot="{ Component }" :key="$route.fullPath">
                <Suspense>
                    <template v-if="Component">
                        <component :is="Component" />
                    </template>

                    <template #fallback> Loading... </template>
                </Suspense>
            </router-view>
        </main>
        <Footer :data="!!data?.bottom_navigation ? data?.bottom_navigation : undefined" />
        <CookieConsent />
    </div>
</template>
<script setup lang="ts">
//! Plugins
import { origin } from '@/utils/constant';
import { fetchAPI } from '@/utils/fetch-api';
import { experimental_createPersister } from '@tanstack/query-persist-client-core';
import { useQuery } from '@tanstack/vue-query';
import { useHead } from '@unhead/vue';
import { del, get, set } from 'idb-keyval';
import { computed, onServerPrefetch, ref } from 'vue';

import { useUserStore } from '@/store/user';

import Footer from '@/template/footer.vue';
import Header from '@/template/header.vue';

const path = '/global';

const metaData = ref<any>(null);

const user = useUserStore();

const router = useRouter();
const route = useRoute();

const canonical = `${origin}${router.resolve(route.name ? { name: route.name } : route).path}`;

const generateMetaTags = () => {
    return {
        title: () => 'AdvisoryCXO',
        link: [{ hid: 'canonical', rel: 'canonical', href: canonical }],
        meta: [
            {
                name: 'robots',
                content: 'index,follow,max-image-preview:large'
            },
            {
                property: 'og:url',
                content: () => canonical
            },
            {
                property: 'og:image',
                content: () => metaData.value?.metaImage?.formats?.thumbnail?.url ?? null
            },
            {
                property: 'og:image:width',
                content: () => metaData.value?.metaImage?.formats?.thumbnail?.width ?? null
            },
            {
                property: 'og:image:height',
                content: () => metaData.value?.metaImage?.formats?.thumbnail?.height ?? null
            },
            {
                property: 'og:image:type',
                content: () => metaData.value?.metaImage?.mime ?? null
            },
            {
                property: 'og:url',
                content: () => metaData.value?.canonicalURL ?? null
            },
            {
                name: 'twitter:card',
                content: 'summary'
            },
            {
                name: 'twitter:image',
                content: () => metaData.value?.metaImage?.formats?.thumbnail?.url ?? null
            },
            {
                name: 'twitter:image:width',
                content: () => metaData.value?.metaImage?.formats?.thumbnail?.width ?? null
            },
            {
                name: 'twitter:image:height',
                content: () => metaData.value?.metaImage?.formats?.thumbnail?.height ?? null
            },
            {
                name: 'twitter:image:type',
                content: () => metaData.value?.metaImage?.mime ?? null
            }
        ]
    };
};

const metaTags = computed(generateMetaTags);

const { data, suspense } = useQuery({
    queryKey: ['navigationQuery'],
    queryFn: async () => await fetchAPI(path),
    persister: experimental_createPersister({
        storage: {
            getItem: (key: string) => get(key),
            setItem: (key: string, value: string) => set(key, value),
            removeItem: (key: string) => del(key)
        }
    }),
    refetchOnMount: false,
    refetchOnWindowFocus: false
});

async function init() {
    await user.fetchUser();

    const { seo } = await fetchAPI(`${path}/meta`);

    if (seo !== null) {
        metaData.value = seo;

        seo?.metaTag.map((el: { attribute: string; title: any; content: any }) => {
            const name = el.attribute !== 'property' ? el.title : null;
            const metaTag: { [key: string]: any; name: any; content: () => any } = {
                [el.attribute]: el.title,
                name,
                content: () => el.content
            };

            metaTags.value.meta.push(metaTag);
        });
    }
}

init();

onServerPrefetch(async () => {
    init();
    suspense();

    useHead(metaTags.value);
});
</script>
